import { css } from 'astroturf';
import React, { Component } from 'react';
import CTA from '../../components/CTA';
import PageHeader from '../../components/PageHeader';
import SEO from '../../components/SEO';
import Layout from '../../layout';
import Punchout from '../../components/Punchout';
import Video from './_Video';
import FeatureList from './_FeatureList';
import Socials from '../../components/Socials';

const styles = css`
  .icon {
    max-width: 100%;
    width: 10rem;
    --white: #fff;
    margin-bottom: 2rem;
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Social media previews tester"
          description="Test your social media previews before they're published. Never again miss typos and og images after you've already shared your links on social media."
          keywords={['']}
          pathname={this.props.location.pathname}
          ogFileName="socialtest"
          ogCustomPath="/og-custom/"
        />
        <PageHeader nobg>
          <h1>
            Test your social media previews <Punchout>before</Punchout> they're published.
          </h1>
          <h2 style={{ padding: 0 }}>
            Never again miss typos and og images after you've already shared your links on social media with {' '}
            <b>pixel-perfect previews</b> for 10 social media sites.
          </h2>
          <Socials />
        </PageHeader>
        <Video />
        <FeatureList />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
