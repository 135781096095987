import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import { InView } from 'react-intersection-observer';

const styles = css`
  .feature {
    max-width: 1024px;
    margin: 0 auto;
  }

  .videocontainer {
    position: relative;
    flex-grow: 1;
    height: 100%;
    aspect-ratio: 100/62.7;
    padding: 0.6rem;
    border-radius: 1rem;
    overflow: visible;
    background: linear-gradient(145deg, #012b, #0126, #012b);
    box-shadow:
      rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
    transform: translateZ(1000px);

    &::after {
      z-index: 2;
      content: '▶';
      place-items: center;
      position: absolute;
      display: block;
      left: calc(50% - 50px);
      top: calc(50% - 50px);
      font-size: 50px;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      color: #fff;
      pointer-events: none;
      opacity: 0;
      transition: 0.2s ease-in-out;
      border-radius: 9999px;
      background: rgba(0, 0, 0, 0.7);
      backdrop-filter: blur(6px);
      padding-left: 8px;
      box-shadow:
        rgba(255, 255, 255, 0.15) 0px 1px 1px 0px inset,
        rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;
    }

    &.paused::after {
      opacity: 0.95;
      transition: 0.1s ease-in-out;
    }
  }

  .video {
    position: absolute;
    inset: 0.6rem;
    width: calc(100% - 1.2rem);
    height: calc(100% - 1.2rem);
    border-radius: 0.4rem;
    background: #f0eef0;
    object-fit: cover;
    box-shadow:
      rgba(255, 255, 255, 0.6) 0px 1px 1px 0px inset,
      rgba(50, 50, 93, 0.7) 0px 5px 10px;
    transform: translateZ(100px);
  }
`;

export default class Feature extends React.Component {
  state = {
    playing: false,
  };

  componentDidMount() {}

  handleClick = () => {
    if (this.video.paused) {
      this.video.play();
      this.setState({ playing: true });
    } else {
      this.video.pause();
      this.setState({ playing: false });
    }
  };

  render() {
    return (
      <StaticQuery
        query={photoquery}
        render={(data) => (
          <InView
            threshold={0.75}
            onChange={(inView) => {
              let promise;
              const shouldPauseOnFirstFrame = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

              if (inView) {
                promise = this.video.play();
                this.setState({ playing: true });
                if (shouldPauseOnFirstFrame) {
                  this.video.pause();
                  this.setState({ playing: false });
                }
              } else {
                this.setState({ playing: false });
                promise = this.video.pause();
              }

              if (promise !== undefined) {
                promise.catch((error) => {}).then(() => {});
              }
            }}
          >
            {({ ref }) => (
              <div ref={ref} className={[styles.feature, this.props.type ? styles[this.props.type] : ''].join(' ')}>
                <div
                  className={[
                    styles.videocontainer,
                    this.state.playing ? styles.playing : styles.paused,
                    this.props.large ? styles.large : '',
                  ].join(' ')}
                >
                  <video
                    className={styles.video}
                    loop
                    muted
                    preload="true"
                    loading="lazy"
                    playsInline
                    ref={(c) => {
                      this.video = c;
                    }}
                    onClick={this.handleClick}
                    title="Click to play"
                  >
                    <source src={data.video.publicURL} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}
          </InView>
        )}
      />
    );
  }
}

const photoquery = graphql`
  query socialMediaQuery {
    video: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
  }
`;
