import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import SmallCTA from '../../components/CTA-small';
import Link from '../../components/link';
import Feature from '../../components/Feature';
import FeatureVideo from '../../components/FeatureVideo';
import Socials from '../../components/Socials';

const styles = css`
  .featureList {
    padding: 0rem 0 5rem;
    text-align: center;

    & > h2 {
      padding: 10rem 2rem 0rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      letter-spacing: -0.015em;
      color: #0a1530;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    & > p {
      font-size: calc(16px + 0.25vw);
      font-weight: 400;
      position: relative;
      max-width: 52rem;
      margin: 0 auto 1.5rem;
      line-height: 1.6;

      padding-left: calc(1rem + env(safe-area-inset-left, 0));
      padding-right: calc(1rem + env(safe-area-inset-right, 0));
      @media (min-width: 770px) {
        font-size: 1.17rem;
      }
    }

    & li {
      color: #0a1530;
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }

  .badge {
    vertical-align: middle;
    font-size: 0.8rem;
    line-height: 0.8rem;
    background: #107db5;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    padding: 3px 5px 1px;
  }
`;

function FeatureList() {
  return (
    <StaticQuery
      query={photoquery}
      render={(data) => (
        <section className={styles.featureList}>
          <h2>Social media previews right inside your browser</h2>
          <p>
            The Polypane Meta panel shows how your site will look on 9 social media sites. It matches the exact sizes,
            colors &amp; fonts, so you won't get any surprises when your links get shared.
          </p>
          <Socials />
          <SmallCTA text="Don't make checking your social media previews an afterthought" />

          <Feature gatsbyImg={data.pixelperfect} type="light" full>
            <h3>Pixel perfect</h3>
            <p>
              We sweat the details to make sure your social media previews look exactly like they will on the social
              media site. We match the exact sizes, colors &amp; fonts.
            </p>
            <p> They're the most accurate social media previews you'll find.</p>
          </Feature>

          <Feature gatsbyImg={data.darklight} type="light" full>
            <h3>Light and dark mode</h3>
            <p>
              We match the light and dark mode of each social media site. Switch between them and check that your image
              looks good in light and dark mode.
            </p>
          </Feature>
          <Feature gatsbyImg={data.uptodate} type="light" full>
            <h3>Always up to date</h3>
            <p>
              Each release we check the social media sites for updates so you always get the most accurate preview there
              is.
            </p>
          </Feature>
          <Feature gatsbyImg={data.bluesky} type="light" full>
            <h3>
              Bluesky preview <span className={styles.badge}>New</span>
            </h3>
            <p>Are you on Bluesky? We've got you covered with our new Bluesky preview.</p>
            <p>
              <i>
                ps. <Link to="https://staging.bsky.app/profile/polypane.app">follow us</Link>, we'll follow you back!
              </i>
            </p>
          </Feature>
          <Feature gatsbyImg={data.warnings} type="light" full>
            <h3>Debugging built-in</h3>
            <p>
              Are things not rendering the way you expect? Our meta tag overview shows you exactly what your site is
              sending to each social media site and warns you about any issues.
            </p>
          </Feature>
          <h2>Want to learn more?</h2>
          <p>
            Check out the documentation of our Meta panel to find all the <i>other</i> things we also check, like
            Robots.txt, webmanifest, icons and more.
          </p>
          <Link className={styles.primaryButton} to="/docs/meta-information/">
            <span>The Polypane Meta Panel</span>
          </Link>
          <p>Or start a trial and try it for yourself!</p>
        </section>
      )}
    />
  );
}

export default FeatureList;

const photoquery = graphql`
  query socialMediaPreviewsFeatures {
    pixelperfect: file(relativePath: { eq: "images/socialmedias/pixelperfect.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    darklight: file(relativePath: { eq: "images/socialmedias/darklight.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    bluesky: file(relativePath: { eq: "images/socialmedias/bluesky.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    warnings: file(relativePath: { eq: "images/socialmedias/warnings.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    uptodate: file(relativePath: { eq: "images/socialmedias/uptodate.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
